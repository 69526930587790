.seyv {
  fill: #c8cfdb;
  cursor: pointer;
  transition: 0.7s;
  &:hover {
    fill: #9eb4e2;
  }
}
.active {
  transition: 0.7s;
  fill: #5973a3;
  cursor: pointer;
}
