.gallery {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%;
  padding: 0 12px;
}
.gallery .pics {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: 12px;
}

.title {
  text-align: center;
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.pics figure img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.pics figure:hover img {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

@media (max-width: 992px) {
  .gallery {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}
@media (max-width: 690px) {
  .gallery {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 950%;
    -moz-column-width: 95%;
    column-width: 95%;
  }
}

.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  transition: opacity 0.4s ease, visibility 0.4s ease,
    transform 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 999;
}

.model.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}
.model img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 0 20px;
  margin: 0 auto;
}
.icon__x {
  position: fixed;
  top: 30px;
  right: 30px;
  width: 3rem;
  height: 3rem;
  padding: 5px;
  color: #ffffff;
  cursor: pointer;
}

.icon__left {
  position: fixed;
  top: 430px;
  left: 70px;
  width: 3rem;
  height: 3rem;
  padding: 5px;
  color: #ffffff;
  cursor: pointer;
}
.icon__right {
  position: fixed;
  top: 430px;
  right: 70px;
  width: 3rem;
  height: 3rem;
  padding: 5px;
  color: #ffffff;
  cursor: pointer;
}
